// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as LevelId from "../../tree/LevelId.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "../../tree/Question.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as VesselId from "../../vessel/VesselId.js";
import * as PageHeader from "../../common/views/PageHeader.js";
import * as PageLayout from "../../common/views/PageLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserProfile from "../../accounts/UserProfile.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";
import * as StillLoading from "../../common/views/StillLoading.js";
import * as ModalBackdrop from "../../common/components/ModalBackdrop.js";
import * as ButtonSlimIcon from "../../common/components/ButtonSlimIcon.js";
import * as ButtonSlimLink from "../../common/components/ButtonSlimLink.js";
import * as Sire2ViewQueue from "./Sire2ViewQueue.js";
import * as Sire2LevelStatus from "../Sire2LevelStatus.js";
import * as Sire2FilterDialog from "./Sire2FilterDialog.js";
import * as ButtonFilterToggle from "../../common/components/ButtonFilterToggle.js";
import * as Sire2LevelChildren from "./Sire2LevelChildren.js";
import * as Sire2NominalFilters from "../Sire2NominalFilters.js";
import * as Sire2RoviqLocations from "../Sire2RoviqLocations.js";
import * as Sire2LevelQuestionView from "./Sire2LevelQuestionView.js";
import * as Sire2CompletionStatusQueue from "./Sire2CompletionStatusQueue.js";

function fromUrl() {
  var vesselId = VesselId.Route.param("vesselId");
  var levelId = LevelId.Route.param("levelId");
  var filters = Sire2NominalFilters.fromURL();
  return {
          vesselId: vesselId,
          levelId: levelId,
          filters: filters
        };
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var onChange = match.onChange;
                var filters = match.filters;
                var count = Sire2NominalFilters.count(filters);
                var max = Core__Option.getOr(vnode.attrs.showMaxFilters, 5);
                if (count >= max) {
                  return React.jsx(ButtonFilterToggle.make, {
                              text: count.toString() + " filter selected",
                              onClick: (function (param) {
                                  onChange(Sire2NominalFilters.Defaults.all());
                                })
                            });
                } else {
                  return [
                          filters.areas.map(function (f) {
                                return React.jsxKeyed(ButtonFilterToggle.make, {
                                            text: Question.QuestionArea.displayName(f),
                                            onClick: (function (param) {
                                                onChange({
                                                      areas: filters.areas.filter(function (v) {
                                                            return v !== f;
                                                          }),
                                                      risks: filters.risks,
                                                      answered: filters.answered,
                                                      ranks: filters.ranks,
                                                      roviq: filters.roviq
                                                    });
                                              })
                                          }, f, undefined);
                              }),
                          filters.risks.map(function (f) {
                                return React.jsxKeyed(ButtonFilterToggle.make, {
                                            text: f === "medium" ? "Medium / Rotational I" : (
                                                f === "low" ? "Low / Rotational II" : "High / Core"
                                              ),
                                            onClick: (function (param) {
                                                onChange({
                                                      areas: filters.areas,
                                                      risks: filters.risks.filter(function (v) {
                                                            return v !== f;
                                                          }),
                                                      answered: filters.answered,
                                                      ranks: filters.ranks,
                                                      roviq: filters.roviq
                                                    });
                                              })
                                          }, f, undefined);
                              }),
                          filters.answered.map(function (f) {
                                return React.jsxKeyed(ButtonFilterToggle.make, {
                                            text: Question.Answered.displayName(f),
                                            onClick: (function (param) {
                                                onChange({
                                                      areas: filters.areas,
                                                      risks: filters.risks,
                                                      answered: filters.answered.filter(function (v) {
                                                            return v !== f;
                                                          }),
                                                      ranks: filters.ranks,
                                                      roviq: filters.roviq
                                                    });
                                              })
                                          }, f, undefined);
                              }),
                          filters.ranks.map(function (f) {
                                return React.jsxKeyed(ButtonFilterToggle.make, {
                                            text: UserProfile.Rank.toString(f),
                                            onClick: (function (param) {
                                                onChange({
                                                      areas: filters.areas,
                                                      risks: filters.risks,
                                                      answered: filters.answered,
                                                      ranks: filters.ranks.filter(function (v) {
                                                            return v !== f;
                                                          }),
                                                      roviq: filters.roviq
                                                    });
                                              })
                                          }, f, undefined);
                              }),
                          filters.roviq.map(function (f) {
                                return React.jsxKeyed(ButtonFilterToggle.make, {
                                            text: Sire2RoviqLocations.RoviqLocation.toString(f),
                                            onClick: (function (param) {
                                                onChange({
                                                      areas: filters.areas,
                                                      risks: filters.risks,
                                                      answered: filters.answered,
                                                      ranks: filters.ranks,
                                                      roviq: filters.roviq.filter(function (v) {
                                                            return v !== f;
                                                          })
                                                    });
                                              })
                                          }, f, undefined);
                              })
                        ];
                }
              }));
}

function toggleFilterDialog(vnode) {
  var s = vnode.state.data;
  if (s.TAG !== "Loaded") {
    return ;
  }
  var s$1 = s._0;
  Mithril.setState(vnode, {
        TAG: "Loaded",
        _0: {
          vesselId: s$1.vesselId,
          levelId: s$1.levelId,
          title: s$1.title,
          guidanceUrl: s$1.guidanceUrl,
          showModal: !s$1.showModal,
          filters: s$1.filters,
          levelStatus: s$1.levelStatus
        }
      });
}

async function load(vnode) {
  console.log("Sire2View", {
        state: vnode.state.data
      });
  var payload = vnode.state.data;
  var vesselId;
  var levelId;
  var filters;
  switch (payload.TAG) {
    case "NotReady" :
        vesselId = payload.vesselId;
        levelId = payload.levelId;
        filters = payload.filters;
        break;
    case "Loaded" :
        var payload$1 = payload._0;
        var match = fromUrl();
        var levelId$1 = match.levelId;
        var vesselId$1 = match.vesselId;
        if (Caml_obj.notequal(levelId$1, payload$1.levelId) || Caml_obj.notequal(vesselId$1, payload$1.vesselId)) {
          Mithril.setState(vnode, {
                TAG: "Updating",
                _0: {
                  vesselId: vesselId$1,
                  levelId: levelId$1,
                  title: payload$1.title,
                  guidanceUrl: payload$1.guidanceUrl,
                  showModal: payload$1.showModal,
                  filters: payload$1.filters,
                  levelStatus: payload$1.levelStatus
                }
              });
          return await load(vnode);
        } else {
          return ;
        }
    case "Updating" :
        var match$1 = payload._0;
        vesselId = match$1.vesselId;
        levelId = match$1.levelId;
        filters = match$1.filters;
        break;
    case "NotFound" :
        var match$2 = fromUrl();
        var levelId$2 = match$2.levelId;
        var vesselId$2 = match$2.vesselId;
        if (Caml_obj.notequal(levelId$2, payload.levelId) || Caml_obj.notequal(vesselId$2, payload.vesselId)) {
          vnode.state.data = {
            TAG: "NotReady",
            vesselId: vesselId$2,
            levelId: levelId$2,
            filters: payload.filters
          };
          return await load(vnode);
        } else {
          return ;
        }
    
  }
  Sire2CompletionStatusQueue.clear();
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match$3 = await Query.read((newrecord.levels = {
          TAG: "Get",
          _0: levelId
        }, newrecord));
  var levels = match$3.levels;
  if (levels.length !== 1) {
    return Mithril.setState(vnode, {
                TAG: "NotFound",
                vesselId: vesselId,
                levelId: levelId,
                filters: filters
              });
  }
  var level = levels[0];
  var title = level.name;
  var guidanceUrl = Core__Option.map(level.guidance_document_id, (function (docId) {
          return URLs.markdownDocument(docId);
        }));
  var levelStatus = await Sire2ViewQueue.replace(function () {
        return Sire2LevelStatus.SireLevelState.getState(vesselId, levelId, Sire2NominalFilters.getStateFilters(filters), true);
      });
  return Mithril.setState(vnode, {
              TAG: "Loaded",
              _0: {
                vesselId: vesselId,
                levelId: levelId,
                title: title,
                guidanceUrl: guidanceUrl,
                showModal: false,
                filters: filters,
                levelStatus: levelStatus
              }
            });
}

async function update(vnode) {
  var payload = vnode.state.data;
  if (payload.TAG !== "Loaded") {
    return ;
  }
  var payload$1 = payload._0;
  var match = fromUrl();
  var filters = match.filters;
  var levelId = match.levelId;
  var vesselId = match.vesselId;
  if (Caml_obj.notequal(levelId, payload$1.levelId) || Caml_obj.notequal(vesselId, payload$1.vesselId) || Caml_obj.notequal(filters, payload$1.filters)) {
    Mithril.setState(vnode, {
          TAG: "Updating",
          _0: {
            vesselId: vesselId,
            levelId: levelId,
            title: payload$1.title,
            guidanceUrl: payload$1.guidanceUrl,
            showModal: payload$1.showModal,
            filters: filters,
            levelStatus: payload$1.levelStatus
          }
        });
    return await load(vnode);
  }
  
}

function updateFilters(vnode, filters) {
  var match = vnode.state.data;
  switch (match.TAG) {
    case "Loaded" :
    case "Updating" :
        break;
    case "NotReady" :
    case "NotFound" :
        return ;
    
  }
  var s = match._0;
  Sire2NominalFilters.updateURL(filters, undefined);
  Mithril.setState(vnode, {
        TAG: "Updating",
        _0: {
          vesselId: s.vesselId,
          levelId: s.levelId,
          title: s.title,
          guidanceUrl: s.guidanceUrl,
          showModal: s.showModal,
          filters: filters,
          levelStatus: s.levelStatus
        }
      });
  load(vnode);
}

function make$1(param) {
  var match = fromUrl();
  var filters = match.filters;
  return Mithril.view(Mithril.onupdateAsync(Mithril.oninitAsync(Mithril.component({
                          TAG: "NotReady",
                          vesselId: match.vesselId,
                          levelId: match.levelId,
                          filters: filters
                        }), load), update), (function (vnode) {
                var match = vnode.state.data;
                switch (match.TAG) {
                  case "NotReady" :
                      return React.jsxs(PageLayout.make, {
                                  header: Caml_option.some(React.jsx(PageHeader.Full.make, {
                                            content: {
                                              NAME: "Title",
                                              VAL: "SIRE 2.0"
                                            }
                                          })),
                                  children: [
                                    React.jsx(ModalBackdrop.make, {}),
                                    ReactDOM.jsx("div", {
                                          children: ReactDOM.jsxs("div", {
                                                children: [
                                                  ReactDOM.jsxs("div", {
                                                        children: [
                                                          ReactDOM.jsx("div", {
                                                                children: React.jsx(ButtonSlimIcon.make, {
                                                                      icon: "filter",
                                                                      onClick: (function (param) {
                                                                          
                                                                        })
                                                                    }),
                                                                className: "tw-flex tw-flex-row tw-items-center tw-gap-2"
                                                              }),
                                                          ReactDOM.jsx("div", {
                                                                children: React.jsx(make, {
                                                                      filters: filters,
                                                                      onChange: (function (param) {
                                                                          
                                                                        })
                                                                    }),
                                                                className: "tw-flex tw-flex-row tw-items-center tw-gap-2"
                                                              })
                                                        ],
                                                        className: "tw-flex tw-flex-col"
                                                      }),
                                                  ReactDOM.jsx("div", {
                                                        children: React.jsx(StillLoading.make, {
                                                              delay: 300
                                                            }),
                                                        className: "t-level-elements"
                                                      })
                                                ],
                                                className: "tw-flex tw-flex-col tw-gap-1"
                                              }),
                                          className: "tw-px-[20px] md:tw-px-[50px] tw-py-3"
                                        })
                                  ]
                                });
                  case "Loaded" :
                      var match$1 = match._0;
                      var levelStatus = match$1.levelStatus;
                      var filters$1 = match$1.filters;
                      var levelId = match$1.levelId;
                      var vesselId = match$1.vesselId;
                      return React.jsx(PageLayout.make, {
                                  header: Caml_option.some(React.jsx(PageHeader.Full.make, {
                                            content: {
                                              NAME: "Title",
                                              VAL: match$1.title
                                            }
                                          })),
                                  children: Caml_option.some(ReactDOM.jsxs("div", {
                                            children: [
                                              match$1.showModal ? React.jsx(Sire2FilterDialog.make, {
                                                      filters: filters$1,
                                                      onChange: (function (f) {
                                                          updateFilters(vnode, f);
                                                        }),
                                                      onClear: (function () {
                                                          updateFilters(vnode, Sire2NominalFilters.Defaults.all());
                                                        }),
                                                      onClose: (function () {
                                                          toggleFilterDialog(vnode);
                                                        })
                                                    }) : Mithril.empty,
                                              ReactDOM.jsxs("div", {
                                                    children: [
                                                      ReactDOM.jsxs("div", {
                                                            children: [
                                                              ReactDOM.jsxs("div", {
                                                                    children: [
                                                                      React.jsx(ButtonSlimIcon.make, {
                                                                            icon: "filter",
                                                                            onClick: (function (param) {
                                                                                toggleFilterDialog(vnode);
                                                                              }),
                                                                            className: "tw-pr-0 tw-pl-0"
                                                                          }),
                                                                      React.jsx(make, {
                                                                            filters: filters$1,
                                                                            onChange: (function (f) {
                                                                                updateFilters(vnode, f);
                                                                              })
                                                                          })
                                                                    ],
                                                                    className: "tw-flex tw-flex-row tw-items-center tw-gap-2 tw-flex-wrap"
                                                                  }),
                                                              Core__Option.getOr(Core__Option.map(match$1.guidanceUrl, (function (href) {
                                                                          return [
                                                                                  ReactDOM.jsx("div", {
                                                                                        className: "tw-flex-grow"
                                                                                      }),
                                                                                  React.jsxs(ButtonSlimLink.make, {
                                                                                        children: [
                                                                                          React.jsx(Feather.make, {
                                                                                                icon: "book_open"
                                                                                              }),
                                                                                          ReactDOM.jsx("span", {
                                                                                                children: "Guidance"
                                                                                              })
                                                                                        ],
                                                                                        href: href
                                                                                      })
                                                                                ];
                                                                        })), Mithril.empty)
                                                            ],
                                                            className: "tw-flex tw-flex-row tw-w-full"
                                                          }),
                                                      ReactDOM.jsxs("div", {
                                                            children: [
                                                              React.jsx(Sire2LevelChildren.make, {
                                                                    vesselId: vesselId,
                                                                    levelId: levelId,
                                                                    levelStatus: levelStatus,
                                                                    filters: filters$1
                                                                  }),
                                                              levelStatus.questions.length > 0 ? React.jsx(Sire2LevelQuestionView.make, {
                                                                      vesselId: vesselId,
                                                                      levelId: levelId,
                                                                      filters: filters$1,
                                                                      levelStatus: levelStatus
                                                                    }) : Mithril.empty
                                                            ],
                                                            className: "t-level-elements"
                                                          })
                                                    ],
                                                    className: "tw-flex tw-flex-col tw-gap-1"
                                                  })
                                            ],
                                            className: "tw-px-[20px] md:tw-px-[50px] tw-py-3"
                                          }))
                                });
                  case "Updating" :
                      var match$2 = match._0;
                      var filters$2 = match$2.filters;
                      return React.jsx(PageLayout.make, {
                                  header: Caml_option.some(React.jsx(PageHeader.Full.make, {
                                            content: {
                                              NAME: "Title",
                                              VAL: match$2.title
                                            }
                                          })),
                                  children: Caml_option.some(ReactDOM.jsxs("div", {
                                            children: [
                                              match$2.showModal ? React.jsx(Sire2FilterDialog.make, {
                                                      filters: filters$2,
                                                      onChange: (function (f) {
                                                          updateFilters(vnode, f);
                                                        }),
                                                      onClear: (function () {
                                                          updateFilters(vnode, Sire2NominalFilters.Defaults.all());
                                                        }),
                                                      onClose: (function () {
                                                          toggleFilterDialog(vnode);
                                                        })
                                                    }) : Mithril.empty,
                                              ReactDOM.jsxs("div", {
                                                    children: [
                                                      ReactDOM.jsx("div", {
                                                            children: ReactDOM.jsxs("div", {
                                                                  children: [
                                                                    ReactDOM.jsxs("div", {
                                                                          children: [
                                                                            React.jsx(ButtonSlimIcon.make, {
                                                                                  icon: "filter",
                                                                                  onClick: (function (param) {
                                                                                      toggleFilterDialog(vnode);
                                                                                    }),
                                                                                  className: "tw-pr-0 tw-pl-0"
                                                                                }),
                                                                            React.jsx(make, {
                                                                                  filters: filters$2,
                                                                                  onChange: (function (f) {
                                                                                      updateFilters(vnode, f);
                                                                                    })
                                                                                })
                                                                          ],
                                                                          className: "tw-flex tw-flex-row tw-items-center tw-gap-2 tw-flex-wrap"
                                                                        }),
                                                                    Core__Option.getOr(Core__Option.map(match$2.guidanceUrl, (function (href) {
                                                                                return [
                                                                                        ReactDOM.jsx("div", {
                                                                                              className: "tw-flex-grow"
                                                                                            }),
                                                                                        React.jsxs(ButtonSlimLink.make, {
                                                                                              children: [
                                                                                                React.jsx(Feather.make, {
                                                                                                      icon: "book_open"
                                                                                                    }),
                                                                                                ReactDOM.jsx("span", {
                                                                                                      children: "Guidance"
                                                                                                    })
                                                                                              ],
                                                                                              href: href
                                                                                            })
                                                                                      ];
                                                                              })), Mithril.empty)
                                                                  ],
                                                                  className: "tw-flex tw-flex-row tw-w-full"
                                                                }),
                                                            className: "tw-flex tw-flex-col"
                                                          }),
                                                      ReactDOM.jsx("div", {
                                                            children: React.jsx(StillLoading.make, {
                                                                  delay: 300
                                                                }),
                                                            className: "t-level-elements"
                                                          })
                                                    ],
                                                    className: "tw-flex tw-flex-col tw-gap-1"
                                                  })
                                            ],
                                            className: "tw-px-[20px] md:tw-px-[50px] tw-py-3"
                                          }))
                                });
                  case "NotFound" :
                      return React.jsx(PageLayout.make, {
                                  header: Caml_option.some(React.jsx(PageHeader.Full.make, {
                                            content: {
                                              NAME: "Title",
                                              VAL: "Not found"
                                            }
                                          })),
                                  children: Caml_option.some(ReactDOM.jsx("div", {
                                            children: ReactDOM.jsx("p", {
                                                  children: "We didn't found the SIRE Chapter"
                                                }),
                                            className: "tw-px-[20px] md:tw-px-[50px] tw-py-3"
                                          }))
                                });
                  
                }
              }));
}

export {
  make$1 as make,
}
/* URLs Not a pure module */
