// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Question from "./tree/Question.js";
import * as LevelType from "./tree/LevelType.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserProfile from "./accounts/UserProfile.js";
import * as LevelRiskStatus from "./tree/LevelRiskStatus.js";
import * as Sire2RoviqLocations from "./sire/Sire2RoviqLocations.js";

function _encodeParam(param) {
  var param$1 = param[0];
  return Curry._2(Prelude.OptionExported.$$Option.map, param[1], (function (value) {
                return encodeURIComponent(param$1) + "=" + encodeURIComponent(value);
              }));
}

function _encodeUrl(url, params) {
  var params$1 = Prelude.$$Array.keepSome(params.map(_encodeParam)).join("&");
  if (params$1.length > 0) {
    return url + "?" + params$1;
  } else {
    return url;
  }
}

function home() {
  return "/";
}

function addProfile(vesselId) {
  return "/user-addprofile/" + vesselId;
}

function editProfile(vesselId, profileId) {
  return "/user-editprofile/" + vesselId + "/" + profileId;
}

function userProfiles() {
  return "/user-profiles/";
}

function vessel(id) {
  return "/vessel/" + id;
}

function vesselCompanyForms(vesselId, companyFormTypeOpt, param) {
  var companyFormType = companyFormTypeOpt !== undefined ? Caml_option.valFromOption(companyFormTypeOpt) : undefined;
  var companyFormType$1 = Prelude.default(companyFormType, "recurrent");
  return "/vessel/" + vesselId + "/company-forms/" + companyFormType$1;
}

function vesselPlannedSections(id) {
  return "/vessel/" + id + "/planned";
}

function vesselSections(vesselId, kind, asPlannedOpt, param) {
  var asPlanned = asPlannedOpt !== undefined ? Caml_option.valFromOption(asPlannedOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/" + kind.VAL, [[
                "asPlanned",
                Curry._2(Prelude.OptionExported.$$Option.map, asPlanned, Prelude.Bool.toString)
              ]]);
}

function sectionComponents(sectionId, componentType, asPlanned) {
  return _encodeUrl("/section/" + sectionId + "/" + componentType, [[
                "asPlanned",
                Prelude.Bool.toString(asPlanned)
              ]]);
}

function sectionImage(sectionId) {
  return "/section/" + sectionId + "/image";
}

function componentProcedures(componentId) {
  return "/component/" + componentId;
}

function startInspectionProcedure(procedureId, rating, picturePromptedOpt, backOpt, isRectificationOpt, draftSubmissionIdOpt, param) {
  var picturePrompted = picturePromptedOpt !== undefined ? Caml_option.valFromOption(picturePromptedOpt) : undefined;
  var back = backOpt !== undefined ? Caml_option.valFromOption(backOpt) : undefined;
  var isRectification = isRectificationOpt !== undefined ? Caml_option.valFromOption(isRectificationOpt) : undefined;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/procedure/" + procedureId, [
              [
                "rating",
                Curry._2(Prelude.OptionExported.$$Option.map, rating, (function (x) {
                        return String(x);
                      }))
              ],
              [
                "picturePrompted",
                Curry._2(Prelude.OptionExported.$$Option.map, picturePrompted, Prelude.Bool.toString)
              ],
              [
                "back",
                Curry._2(Prelude.OptionExported.$$Option.map, back, (function (prim) {
                        return String(prim);
                      }))
              ],
              [
                "isRectification",
                Curry._2(Prelude.OptionExported.$$Option.map, isRectification, Prelude.Bool.toString)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]
            ]);
}

function startWeightMeasurementInspectionProcedure(procedureId, inspectionIdOpt, backOpt, updateOpt, draftSubmissionIdOpt, param) {
  var inspectionId = inspectionIdOpt !== undefined ? Caml_option.valFromOption(inspectionIdOpt) : undefined;
  var back = backOpt !== undefined ? Caml_option.valFromOption(backOpt) : undefined;
  var update = updateOpt !== undefined ? updateOpt : false;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/weight-measurement-procedure/" + procedureId, [
              [
                "inspectionId",
                Curry._2(Prelude.OptionExported.$$Option.map, inspectionId, (function (prim) {
                        return prim;
                      }))
              ],
              [
                "back",
                Curry._2(Prelude.OptionExported.$$Option.map, back, (function (prim) {
                        return String(prim);
                      }))
              ],
              [
                "update",
                Prelude.Bool.toString(update)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]
            ]);
}

function startDateInspection(procedureId, draftSubmissionIdOpt, param) {
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/date-procedure/" + procedureId, [[
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]]);
}

function startTextInspection(procedureId, draftSubmissionIdOpt, param) {
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/text-procedure/" + procedureId, [[
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]]);
}

function startIntegerInspection(procedureId, draftSubmissionIdOpt, param) {
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/integer-procedure/" + procedureId, [[
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]]);
}

function startSignatureInspection(procedureId, inspectionIdOpt, updateOpt, draftSubmissionIdOpt, param) {
  var inspectionId = inspectionIdOpt !== undefined ? Caml_option.valFromOption(inspectionIdOpt) : undefined;
  var update = updateOpt !== undefined ? updateOpt : false;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/signature-procedure/" + procedureId, [
              [
                "inspectionId",
                Curry._2(Prelude.OptionExported.$$Option.map, inspectionId, (function (prim) {
                        return prim;
                      }))
              ],
              [
                "update",
                Prelude.Bool.toString(update)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]
            ]);
}

function editInspection(inspectionId) {
  return "/inspection/" + inspectionId + "/edit";
}

function companyFormSubmissions(companyFormId) {
  return "/company-form/" + companyFormId + "/";
}

function newCompanyFormSubmission(companyFormId, draftSubmissionId, targetDateOpt, param) {
  var targetDate = targetDateOpt !== undefined ? Caml_option.valFromOption(targetDateOpt) : undefined;
  return _encodeUrl("/company-form/" + companyFormId + "/submission/" + draftSubmissionId, [[
                "targetDate",
                Curry._2(Prelude.OptionExported.$$Option.map, targetDate, (function (prim) {
                        return prim.toISOString();
                      }))
              ]]);
}

function deficiencyList(deficiencyStatus, profile) {
  return _encodeUrl("/deficiencies/" + deficiencyStatus, [[
                "profile",
                profile
              ]]);
}

function deficiency(deficiencyId) {
  return "/deficiency/" + deficiencyId;
}

function markdownDocument(documentId) {
  return "/document/" + documentId;
}

function vesselSIRE(vesselId) {
  return "/vessel/" + vesselId + "/sire";
}

function vesselLevel(vesselId, levelId) {
  return "/vessel/" + vesselId + "/level/" + levelId;
}

function vesselLevelByKind(vesselId, levelId, kind, areas, risks, answered, ranks, roviq) {
  var areas$1 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, areas, (function (ls) {
              return ls.map(function (x) {
                            return Question.QuestionArea.tToJs(x);
                          }).join(",");
            })), "");
  var risks$1 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, risks, (function (ls) {
              return ls.map(LevelRiskStatus.tToJs).join(",");
            })), "");
  var answered$1 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, answered, (function (ls) {
              return ls.map(function (x) {
                            return Question.Answered.tToJs(x);
                          }).join(",");
            })), "");
  var ranks$1 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, ranks, (function (ls) {
              return ls.map(function (x) {
                            return UserProfile.Rank.crewToJs(x);
                          }).join(",");
            })), "");
  var roviq$1 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, roviq, (function (ls) {
              return ls.map(function (x) {
                            return Sire2RoviqLocations.RoviqLocation.tToJs(x);
                          }).join(",");
            })), "");
  if (kind === "sire" || kind === "sire_chapter") {
    return "/vessel/" + vesselId + "/sire/" + levelId + "?areas=" + areas$1 + "&risks=" + risks$1 + "&answered=" + answered$1 + "&ranks=" + ranks$1 + "&roviq=" + roviq$1;
  } else {
    return "/vessel/" + vesselId + "/level/" + levelId + "?areas=" + areas$1 + "&risks=" + risks$1;
  }
}

function vesselTreeKind(vesselId, kind) {
  return "/vessel/" + vesselId + "/tree/" + LevelType.Kind.toString(kind);
}

function levelSubmissions(levelId, vesselId) {
  return "/vessel/" + vesselId + "/level-submission/" + levelId + "/";
}

function levelSubmission(vesselId, levelId, draftSubmissionIdOpt, targetDateOpt, param) {
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  var targetDate = targetDateOpt !== undefined ? Caml_option.valFromOption(targetDateOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/level/" + levelId + "/submission/", [
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ],
              [
                "targetDate",
                Curry._2(Prelude.OptionExported.$$Option.map, targetDate, (function (prim) {
                        return prim.toISOString();
                      }))
              ]
            ]);
}

function yesNoQuestionWidgetEditor(createNewOpt, isRectificationOpt, valueOpt, draftSubmissionIdOpt, stepOpt, vesselId, levelId, questionId) {
  var createNew = createNewOpt !== undefined ? createNewOpt : true;
  var isRectification = isRectificationOpt !== undefined ? isRectificationOpt : false;
  var value = valueOpt !== undefined ? Caml_option.valFromOption(valueOpt) : undefined;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  var step = stepOpt !== undefined ? Caml_option.valFromOption(stepOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/level/" + levelId + "/question/" + questionId + "/edit-bool", [
              [
                "createNew",
                Prelude.Bool.toString(createNew)
              ],
              [
                "isRectification",
                Prelude.Bool.toString(isRectification)
              ],
              [
                "value",
                Curry._2(Prelude.OptionExported.$$Option.map, value, Prelude.Bool.toString)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ],
              [
                "step",
                Curry._2(Prelude.OptionExported.$$Option.map, step, (function (prim) {
                        return String(prim);
                      }))
              ]
            ]);
}

function okNotOkQuestionWidgetEditor(createNewOpt, isRectificationOpt, valueOpt, draftSubmissionIdOpt, stepOpt, vesselId, levelId, questionId) {
  var createNew = createNewOpt !== undefined ? createNewOpt : true;
  var isRectification = isRectificationOpt !== undefined ? isRectificationOpt : false;
  var value = valueOpt !== undefined ? Caml_option.valFromOption(valueOpt) : undefined;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  var step = stepOpt !== undefined ? Caml_option.valFromOption(stepOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/level/" + levelId + "/question/" + questionId + "/edit-ok-or-not", [
              [
                "createNew",
                Prelude.Bool.toString(createNew)
              ],
              [
                "isRectification",
                Prelude.Bool.toString(isRectification)
              ],
              [
                "value",
                Curry._2(Prelude.OptionExported.$$Option.map, value, Prelude.Bool.toString)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ],
              [
                "step",
                Curry._2(Prelude.OptionExported.$$Option.map, step, (function (prim) {
                        return String(prim);
                      }))
              ]
            ]);
}

function ratingQuestionWidgetEditor(createNewOpt, isRectificationOpt, draftSubmissionIdOpt, stepOpt, vesselId, levelId, questionId) {
  var createNew = createNewOpt !== undefined ? createNewOpt : true;
  var isRectification = isRectificationOpt !== undefined ? isRectificationOpt : false;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  var step = stepOpt !== undefined ? Caml_option.valFromOption(stepOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/level/" + levelId + "/question/" + questionId + "/edit-rating", [
              [
                "createNew",
                Prelude.Bool.toString(createNew)
              ],
              [
                "isRectification",
                Prelude.Bool.toString(isRectification)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ],
              [
                "step",
                Curry._2(Prelude.OptionExported.$$Option.map, step, (function (prim) {
                        return String(prim);
                      }))
              ]
            ]);
}

function dateQuestionWidgetEditor(createNewOpt, isRectificationOpt, draftSubmissionIdOpt, vesselId, levelId, questionId) {
  var createNew = createNewOpt !== undefined ? createNewOpt : true;
  var isRectification = isRectificationOpt !== undefined ? isRectificationOpt : false;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/level/" + levelId + "/question/" + questionId + "/edit-date", [
              [
                "createNew",
                Prelude.Bool.toString(createNew)
              ],
              [
                "isRectification",
                Prelude.Bool.toString(isRectification)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]
            ]);
}

function textQuestionWidgetEditor(createNewOpt, isRectificationOpt, draftSubmissionIdOpt, vesselId, levelId, questionId) {
  var createNew = createNewOpt !== undefined ? createNewOpt : true;
  var isRectification = isRectificationOpt !== undefined ? isRectificationOpt : false;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/level/" + levelId + "/question/" + questionId + "/edit-text", [
              [
                "createNew",
                Prelude.Bool.toString(createNew)
              ],
              [
                "isRectification",
                Prelude.Bool.toString(isRectification)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]
            ]);
}

function intQuestionWidgetEditor(createNewOpt, isRectificationOpt, draftSubmissionIdOpt, vesselId, levelId, questionId) {
  var createNew = createNewOpt !== undefined ? createNewOpt : true;
  var isRectification = isRectificationOpt !== undefined ? isRectificationOpt : false;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/level/" + levelId + "/question/" + questionId + "/edit-int", [
              [
                "createNew",
                Prelude.Bool.toString(createNew)
              ],
              [
                "isRectification",
                Prelude.Bool.toString(isRectification)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]
            ]);
}

function photoQuestionWidgetEditor(createNewOpt, isRectificationOpt, draftSubmissionIdOpt, stepOpt, vesselId, levelId, questionId) {
  var createNew = createNewOpt !== undefined ? createNewOpt : true;
  var isRectification = isRectificationOpt !== undefined ? isRectificationOpt : false;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  var step = stepOpt !== undefined ? Caml_option.valFromOption(stepOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/level/" + levelId + "/question/" + questionId + "/edit-photo", [
              [
                "createNew",
                Prelude.Bool.toString(createNew)
              ],
              [
                "isRectification",
                Prelude.Bool.toString(isRectification)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ],
              [
                "step",
                Curry._2(Prelude.OptionExported.$$Option.map, step, (function (prim) {
                        return String(prim);
                      }))
              ]
            ]);
}

function signatureQuestionWidgetEditor(createNewOpt, isRectificationOpt, draftSubmissionIdOpt, vesselId, levelId, questionId) {
  var createNew = createNewOpt !== undefined ? createNewOpt : true;
  var isRectification = isRectificationOpt !== undefined ? isRectificationOpt : false;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/level/" + levelId + "/question/" + questionId + "/edit-signature", [
              [
                "createNew",
                Prelude.Bool.toString(createNew)
              ],
              [
                "isRectification",
                Prelude.Bool.toString(isRectification)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]
            ]);
}

function multipleDatesQuestionWidgetEditor(createNewOpt, isRectificationOpt, draftSubmissionIdOpt, vesselId, levelId, questionId) {
  var createNew = createNewOpt !== undefined ? createNewOpt : true;
  var isRectification = isRectificationOpt !== undefined ? isRectificationOpt : false;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/level/" + levelId + "/question/" + questionId + "/edit-multiple-dates", [
              [
                "createNew",
                Prelude.Bool.toString(createNew)
              ],
              [
                "isRectification",
                Prelude.Bool.toString(isRectification)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]
            ]);
}

function multipleDatesYesNoQuestionWidgetEditor(createNewOpt, isRectificationOpt, draftSubmissionIdOpt, vesselId, levelId, questionId) {
  var createNew = createNewOpt !== undefined ? createNewOpt : true;
  var isRectification = isRectificationOpt !== undefined ? isRectificationOpt : false;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/level/" + levelId + "/question/" + questionId + "/edit-multiple-dates-yes-no", [
              [
                "createNew",
                Prelude.Bool.toString(createNew)
              ],
              [
                "isRectification",
                Prelude.Bool.toString(isRectification)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]
            ]);
}

function multipleDatesIntegersQuestionWidgetEditor(createNewOpt, isRectificationOpt, draftSubmissionIdOpt, vesselId, levelId, questionId) {
  var createNew = createNewOpt !== undefined ? createNewOpt : true;
  var isRectification = isRectificationOpt !== undefined ? isRectificationOpt : false;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/level/" + levelId + "/question/" + questionId + "/edit-multiple-dates-integers", [
              [
                "createNew",
                Prelude.Bool.toString(createNew)
              ],
              [
                "isRectification",
                Prelude.Bool.toString(isRectification)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]
            ]);
}

function multipleDatesTextsQuestionWidgetEditor(createNewOpt, isRectificationOpt, draftSubmissionIdOpt, vesselId, levelId, questionId) {
  var createNew = createNewOpt !== undefined ? createNewOpt : true;
  var isRectification = isRectificationOpt !== undefined ? isRectificationOpt : false;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/level/" + levelId + "/question/" + questionId + "/edit-multiple-dates-texts", [
              [
                "createNew",
                Prelude.Bool.toString(createNew)
              ],
              [
                "isRectification",
                Prelude.Bool.toString(isRectification)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]
            ]);
}

function multipleIntegersQuestionWidgetEditor(createNewOpt, isRectificationOpt, draftSubmissionIdOpt, vesselId, levelId, questionId) {
  var createNew = createNewOpt !== undefined ? createNewOpt : true;
  var isRectification = isRectificationOpt !== undefined ? isRectificationOpt : false;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/level/" + levelId + "/question/" + questionId + "/edit-multiple-integers", [
              [
                "createNew",
                Prelude.Bool.toString(createNew)
              ],
              [
                "isRectification",
                Prelude.Bool.toString(isRectification)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]
            ]);
}

function multipleTextsQuestionWidgetEditor(createNewOpt, isRectificationOpt, draftSubmissionIdOpt, vesselId, levelId, questionId) {
  var createNew = createNewOpt !== undefined ? createNewOpt : true;
  var isRectification = isRectificationOpt !== undefined ? isRectificationOpt : false;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/level/" + levelId + "/question/" + questionId + "/edit-multiple-texts", [
              [
                "createNew",
                Prelude.Bool.toString(createNew)
              ],
              [
                "isRectification",
                Prelude.Bool.toString(isRectification)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]
            ]);
}

function quizWidgetEditor(createNewOpt, isRectificationOpt, draftSubmissionIdOpt, vesselId, levelId, questionId) {
  var createNew = createNewOpt !== undefined ? createNewOpt : true;
  var isRectification = isRectificationOpt !== undefined ? isRectificationOpt : false;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/level/" + levelId + "/question/" + questionId + "/edit-quiz", [
              [
                "createNew",
                Prelude.Bool.toString(createNew)
              ],
              [
                "isRectification",
                Prelude.Bool.toString(isRectification)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]
            ]);
}

function weightInfoQuestionWidgetEditor(createNewOpt, isRectificationOpt, draftSubmissionIdOpt, vesselId, levelId, questionId) {
  var createNew = createNewOpt !== undefined ? createNewOpt : true;
  var isRectification = isRectificationOpt !== undefined ? isRectificationOpt : false;
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/level/" + levelId + "/question/" + questionId + "/edit-weight-info", [
              [
                "createNew",
                Prelude.Bool.toString(createNew)
              ],
              [
                "isRectification",
                Prelude.Bool.toString(isRectification)
              ],
              [
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]
            ]);
}

function createAnswerNotApplicable(draftSubmissionIdOpt, vesselId, levelId, questionId) {
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  return _encodeUrl("/vessel/" + vesselId + "/level/" + levelId + "/question/" + questionId + "/not-applicable", [[
                "draftSubmissionId",
                Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (prim) {
                        return prim;
                      }))
              ]]);
}

function answerDetails(vesselId, levelId, answerId) {
  return "/vessel/" + vesselId + "/level/" + levelId + "/answer/" + answerId + "/details";
}

function answerEditorUrl(createNewOpt, isRectificationOpt, level, question, vessel_id) {
  var createNew = createNewOpt !== undefined ? createNewOpt : true;
  var isRectification = isRectificationOpt !== undefined ? isRectificationOpt : false;
  var match = question.kind;
  if (typeof match !== "object") {
    if (match === "PhotoQuestion") {
      return photoQuestionWidgetEditor(createNew, isRectification, undefined, undefined, vessel_id, level.id, question.id);
    } else if (match === "TextQuestion") {
      return textQuestionWidgetEditor(createNew, isRectification, undefined, vessel_id, level.id, question.id);
    } else if (match === "DateQuestion") {
      return dateQuestionWidgetEditor(createNew, isRectification, undefined, vessel_id, level.id, question.id);
    } else if (match === "SignatureQuestion") {
      return signatureQuestionWidgetEditor(createNew, isRectification, undefined, vessel_id, level.id, question.id);
    } else {
      return intQuestionWidgetEditor(createNew, isRectification, undefined, vessel_id, level.id, question.id);
    }
  }
  var variant = match.NAME;
  if (variant === "OKNotOKQuestion") {
    return okNotOkQuestionWidgetEditor(createNew, undefined, undefined, undefined, 1, vessel_id, level.id, question.id);
  } else if (variant === "MultipleTextsQuestion") {
    return multipleTextsQuestionWidgetEditor(createNew, isRectification, undefined, vessel_id, level.id, question.id);
  } else if (variant === "RatingQuestion") {
    return ratingQuestionWidgetEditor(createNew, isRectification, undefined, undefined, vessel_id, level.id, question.id);
  } else if (variant === "WeightInfoQuestion") {
    return weightInfoQuestionWidgetEditor(createNew, isRectification, undefined, vessel_id, level.id, question.id);
  } else if (variant === "MultipleDatesTextsQuestion") {
    return multipleDatesTextsQuestionWidgetEditor(createNew, isRectification, undefined, vessel_id, level.id, question.id);
  } else if (variant === "MultipleDatesYesNoQuestion") {
    return multipleDatesYesNoQuestionWidgetEditor(createNew, isRectification, undefined, vessel_id, level.id, question.id);
  } else if (variant === "MultipleIntegersQuestion") {
    return multipleIntegersQuestionWidgetEditor(createNew, isRectification, undefined, vessel_id, level.id, question.id);
  } else if (variant === "YesNoQuestion") {
    return yesNoQuestionWidgetEditor(createNew, isRectification, undefined, undefined, undefined, vessel_id, level.id, question.id);
  } else if (variant === "MultipleDatesQuestion") {
    return multipleDatesQuestionWidgetEditor(createNew, isRectification, undefined, vessel_id, level.id, question.id);
  } else if (variant === "Quiz") {
    return quizWidgetEditor(createNew, isRectification, undefined, vessel_id, level.id, question.id);
  } else {
    return multipleDatesIntegersQuestionWidgetEditor(createNew, isRectification, undefined, vessel_id, level.id, question.id);
  }
}

function r4sListUrl(vesselId) {
  return "/r4s/" + vesselId;
}

function r4sCheckListUrl(checklistId) {
  return "/r4schecklist/" + checklistId;
}

export {
  _encodeParam ,
  _encodeUrl ,
  home ,
  addProfile ,
  editProfile ,
  userProfiles ,
  vessel ,
  vesselCompanyForms ,
  vesselPlannedSections ,
  vesselSections ,
  sectionComponents ,
  sectionImage ,
  componentProcedures ,
  startInspectionProcedure ,
  startWeightMeasurementInspectionProcedure ,
  startDateInspection ,
  startTextInspection ,
  startIntegerInspection ,
  startSignatureInspection ,
  editInspection ,
  companyFormSubmissions ,
  newCompanyFormSubmission ,
  deficiencyList ,
  deficiency ,
  markdownDocument ,
  vesselSIRE ,
  vesselLevel ,
  vesselLevelByKind ,
  vesselTreeKind ,
  levelSubmissions ,
  levelSubmission ,
  yesNoQuestionWidgetEditor ,
  okNotOkQuestionWidgetEditor ,
  ratingQuestionWidgetEditor ,
  dateQuestionWidgetEditor ,
  textQuestionWidgetEditor ,
  intQuestionWidgetEditor ,
  photoQuestionWidgetEditor ,
  signatureQuestionWidgetEditor ,
  multipleDatesQuestionWidgetEditor ,
  multipleDatesYesNoQuestionWidgetEditor ,
  multipleDatesIntegersQuestionWidgetEditor ,
  multipleDatesTextsQuestionWidgetEditor ,
  multipleIntegersQuestionWidgetEditor ,
  multipleTextsQuestionWidgetEditor ,
  quizWidgetEditor ,
  weightInfoQuestionWidgetEditor ,
  createAnswerNotApplicable ,
  answerDetails ,
  answerEditorUrl ,
  r4sListUrl ,
  r4sCheckListUrl ,
}
/* Question Not a pure module */
