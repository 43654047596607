// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ApiMaker from "../../../libs/ApiMaker.js";
import * as Sire2RoviqLocations from "../../sire/Sire2RoviqLocations.js";

var url = "/sire/roviq-locations";

var fields = [
  [
    "id",
    "Int"
  ],
  [
    "question_id",
    "String"
  ],
  [
    "vessel_id",
    "String"
  ],
  [
    "level_id",
    "String"
  ],
  [
    "locations",
    {
      TAG: "Collection",
      _0: Sire2RoviqLocations.RoviqLocation.Deserializer
    }
  ]
];

var Definition = {
  url: url,
  fields: fields
};

var include = ApiMaker.MakeReadonlyAPIEndpoint({
      fields: fields,
      url: url
    });

var Deserializer = include.Deserializer;

var $$URL = include.$$URL;

var UnexpectedAPIPayload = include.UnexpectedAPIPayload;

var getMany = include.getMany;

var getAll = include.getAll;

var get = include.get;

var options = include.options;

export {
  Definition ,
  Deserializer ,
  $$URL ,
  UnexpectedAPIPayload ,
  getMany ,
  getAll ,
  get ,
  options ,
}
/* include Not a pure module */
